import { useCallback } from "react";
import { toast } from "shared/providers/toast/toast.package";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "shared/providers/hook-form/resolver.package";
import * as yup from "shared/providers/yup/yup.package";
import isValidDomain from "is-valid-domain";

import {
  useCreateDistrictMutation,
  GetDistrictsDocument,
} from "../../../generated/graphql";

import {
  BSContent,
  BSNavigation,
} from "shared/components/box-structure/box-structure.styles";
import Button from "shared/components/button/button.component";
import Title from "shared/components/title/title.styles";
import Modal from "shared/components/modal/modal.component";
import Form from "shared/components/form/form.component";
import Input from "shared/components/form/input/input.component";
import Uploader from "../../../components/uploader/uploader.component";
import Select from "shared/components/form/select/select.component";

import { TbX } from "shared/providers/react-icons/tb.package";

import timezonesOptions from "shared/common/data/timezones";

import { INewDistrictFields, INewDistrictProps } from "./new-district.interfaces";

const schema = yup.object().shape({
  name: yup.string().required(),
  url: yup
    .string()
    .required()
    .test("is-valid-domain", (value) => {
      return value
        ? isValidDomain(value, {
            subdomain: true,
          })
        : false;
    }),
  timezone: yup.object().shape({
    value: yup.string().required(),
  }),
});

const NewDistrict = ({ closeHandler }: INewDistrictProps) => {
  const [addDistrict, { loading: saving }] = useCreateDistrictMutation({
    onCompleted: () => {
      toast.success("Success!");
      closeHandler();
    },
    onError: (err) => {
      toast.error(err.message);
    },
    refetchQueries: [GetDistrictsDocument],
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<INewDistrictFields>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      url: "",
      timezone: null,
      logo: null,
      background: null,
      favicon: null,
    },
  });

  const onSubmit: SubmitHandler<INewDistrictFields> = useCallback(
    (newData) => {
      const apiData = {
        name: newData.name,
        url: newData.url,
        timezone: newData.timezone!.value,
        logo: newData.logo || null,
        background: newData.background || null,
        favicon: newData.favicon || null,
      };

      addDistrict({
        variables: {
          data: apiData,
        },
      });
    },
    [addDistrict]
  );

  return (
    <Modal closeHandler={closeHandler}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BSNavigation
          $spacing={{
            p: "12px 16px",
          }}
          isSticky
          $bgColor="#ffffff"
          hasBottomBorder
        >
          <Title level={6} as="h6">
            Add new district
          </Title>

          <div className="ml-auto">
            <Button
              $size="small"
              variant="secondary"
              onClick={closeHandler}
              prependElement={<TbX />}
              iconOnly
            />
          </div>
        </BSNavigation>

        <BSContent
          $spacing={{
            p: "16px 16px 8px",
          }}
        >
          <Input
            {...register("name", {
              required: true,
            })}
            label="Name"
            placeholder="Enter name"
            required
            hasError={!!errors?.name}
            errorMessage={errors?.name ? "Name is required" : undefined}
          />

          <Input
            {...register("url", {
              required: true,
            })}
            label="URL"
            placeholder="Enter domain URL"
            required
            hasError={!!errors?.url}
            errorMessage={
              errors?.url ? "Enter valid domain (Example: google.com)" : undefined
            }
          />

          <Controller
            control={control}
            name="timezone"
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...rest } }) => (
              <Select
                label="Timezone"
                options={timezonesOptions}
                required
                placeholder="Select timezone"
                hasError={!!errors?.timezone}
                errorMessage={errors?.timezone ? "Timezone is required" : undefined}
                {...rest}
              />
            )}
          />

          <Controller
            control={control}
            name="background"
            render={({ field: { ref, ...rest } }) => (
              <Uploader
                id="district-background"
                label="Background"
                valueImagePreview
                showFooter
                dropZoneOptions={{
                  accept: {
                    "image/*": [".png", ".jpeg", ".jpg"],
                  },
                }}
                placeholderDescription="Supported formats: JPG, JPEG, PNG"
                onDelete={() => setValue("background", null)}
                {...rest}
              />
            )}
          />

          <Controller
            control={control}
            name="logo"
            render={({ field: { ref, ...rest } }) => (
              <Uploader
                id="district-logo"
                label="Logo"
                valueImagePreview
                showFooter
                dropZoneOptions={{
                  accept: {
                    "image/*": [".png", ".jpeg", ".jpg"],
                  },
                }}
                placeholderDescription="Supported formats: JPG, JPEG, PNG"
                onDelete={() => setValue("logo", null)}
                {...rest}
              />
            )}
          />
          <Controller
            control={control}
            name="favicon"
            render={({ field: { ref, ...rest } }) => (
              <Uploader
                id="district-favicon"
                label="Favicon"
                valueImagePreview
                showFooter
                dropZoneOptions={{
                  accept: {
                    "image/png": [".png"],
                  },
                }}
                placeholderDescription="Supported formats: PNG"
                onDelete={() => setValue("favicon", null)}
                {...rest}
              />
            )}
          />
        </BSContent>

        <BSNavigation
          $spacing={{
            p: "12px 16px",
          }}
          hasTopBorder
          isSticky
          isFooter
          $bgColor="#ffffff"
        >
          <Button
            onClick={closeHandler}
            $spacing={{
              mr: "10px",
            }}
            variant="secondary"
          >
            Close
          </Button>

          <Button type="submit" isLoading={saving}>
            Add district
          </Button>
        </BSNavigation>
      </Form>
    </Modal>
  );
};

export default NewDistrict;
