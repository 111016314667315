import { getLocalStorageItem } from "shared/utils/local-storage";

import { DistrictAdminFieldsFragment, useMeQuery } from "../generated/graphql";
import { ADMIN_AUTH_TOKEN } from "shared/common/data/constants";

export interface IAuthUser {
  user?: DistrictAdminFieldsFragment;
  loading?: boolean;
}

/**
 * Get current logged user
 * @returns logged user object
 */

export const useAuth = (): IAuthUser => {
  const token = getLocalStorageItem(ADMIN_AUTH_TOKEN);

  const { data, loading } = useMeQuery({
    skip: !token,
  });

  return {
    user: data?.me,
    loading,
  };
};
