import styled, { css } from "styled-components";
import { IInputStyles } from "./input.interfaces";
import { flex, spacing } from "../../../styles/Theme/styled-options";

import rem from "../../../utils/rem";
import { transparentize } from "polished";

const InputWrapper = styled.div<IInputStyles>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spaceRadius.form};
  width: ${({ $width }) => $width};
  max-width: 100%;
  ${spacing};
  ${flex};

  .input__container {
    position: relative;
  }

  input {
    min-height: ${({ theme, $size }) => theme.heights[$size || "large"]};
    padding: ${({ hasElementAfter, hasElementBefore, $size }) =>
      $size === "medium"
        ? hasElementAfter && hasElementBefore
          ? "9px 33px"
          : hasElementAfter
          ? "9px 33px 9px 9px"
          : hasElementBefore
          ? "9px 9px 9px 33px"
          : "9px"
        : $size === "small"
        ? hasElementAfter && hasElementBefore
          ? "7px 29px"
          : hasElementAfter
          ? "7px 29px 7px 7px"
          : hasElementBefore
          ? "7px 7px 7px 29px"
          : "7px"
        : hasElementAfter && hasElementBefore
        ? "11px 37px"
        : hasElementAfter
        ? "11px 37px 11px 11px"
        : hasElementBefore
        ? "11px 11px 11px 37px"
        : "11px"};
    background-color: ${({ variant, hasError, theme }) =>
      variant === "transparent"
        ? hasError
          ? transparentize(0.9, theme.colors.red.base)
          : "rgba(255, 255, 255, 0.1)"
        : "#ffffff"};
    border-color: ${({ theme, hasError, variant }) =>
      hasError
        ? variant === "transparent"
          ? transparentize(0.9, theme.colors.red.base)
          : theme.colors.red.base
        : variant === "transparent"
        ? "rgba(255, 255, 255, 0.1)"
        : theme.colors.grey[100]};
    border-radius: ${({ theme }) => theme.spaceRadius.xxms};
    border-width: 1px;
    border-style: solid;
    appearance: none;
    outline: none;
    cursor: ${({ customCursor }) => customCursor};
    width: 100%;
    color: ${({ theme, variant }) =>
      variant === "transparent" ? "#ffffff" : theme.colors.darkBlue[500]};
    font-size: ${rem(14)};
    line-height: 1.15;
    font-family: ${({ theme }) => theme.typography.font};
    transition: all 0.1s ease;

    &::placeholder {
      color: ${({ theme, variant, hasError }) =>
        hasError
          ? theme.colors.red.base
          : variant === "transparent"
          ? theme.colors.grey[600]
          : theme.colors.grey[700]};
      transition: color 0.2s ease;
    }

    &::-webkit-search-cancel-button {
      filter: ${({ variant }) =>
        variant === "transparent"
          ? "brightness(0) saturate(100%) invert(100%) sepia(95%) saturate(21%) hue-rotate(282deg) brightness(104%) contrast(105%)"
          : "brightness(0) saturate(100%) invert(9%) sepia(22%) saturate(1485%) hue-rotate(182deg) brightness(97%) contrast(92%)"};
      cursor: pointer;
    }

    &:hover:not(:disabled)::placeholder,
    &:focus:not(:disabled)::placeholder {
      color: ${({ variant, theme }) =>
        variant === "transparent" ? "#ffffff" : theme.colors.grey[700]};
    }

    &:hover:not(:disabled) {
      border-color: ${({ variant, theme }) =>
        variant === "transparent"
          ? "rgba(255, 255, 255, 0.2)"
          : theme.colors.grey[100]};
      background-color: ${({ variant, theme }) =>
        variant === "transparent"
          ? "rgba(255, 255, 255, 0.2)"
          : theme.colors.grey[50]};
    }

    &:focus:not(:disabled) {
      border-color: ${({ variant, theme }) =>
        variant === "transparent"
          ? "rgba(255, 255, 255, 0.1)"
          : theme.colors.primary.base};
      background-color: ${({ variant }) =>
        variant === "transparent" ? "rgba(255, 255, 255, 0.1)" : "#ffffff"};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.grey[600]};
      background-color: ${({ theme, variant }) =>
        variant === "transparent"
          ? "rgba(255, 255, 255, 0.2)"
          : theme.colors.grey[100]};
      border-color: ${({ theme, variant }) =>
        variant === "transparent"
          ? "rgba(255, 255, 255, 0.2)"
          : theme.colors.grey[100]};
      cursor: not-allowed;

      &::placeholder {
        color: ${({ theme }) => theme.colors.grey[600]};
      }
    }
  }

  .additional-input-element {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${rem(14)};
    color: ${({ theme, variant }) =>
      variant === "transparent" ? theme.colors.grey[600] : theme.colors.grey[700]};
    transition: color 0.2s ease;

    > svg {
      display: block;
    }

    &--before {
      left: ${({ $size }) =>
        $size === "medium" ? "10px" : $size === "small" ? "8px" : "12px"};
      color: ${({ theme, hasError }) =>
        hasError ? theme.colors.red.base : undefined};
      pointer-events: none;

      ${({ variant, theme }) =>
        css`
          &:has(~ input:not(:disabled):hover) {
            color: ${variant === "transparent" ? "#ffffff" : theme.colors.grey[700]};
          }

          &:has(~ input:not(:disabled):focus) {
            color: ${theme.colors.primary.base};
          }

          &:has(~ input:disabled) {
            color: ${theme.colors.grey[600]};
          }
        `};
    }

    &--after {
      right: ${({ $size }) =>
        $size === "medium" ? "10px" : $size === "small" ? "8px" : "12px"};
    }
  }
`;

export default InputWrapper;
