import styled, { css } from "styled-components";

import {
  IUploaderWrapperStyles,
  IUploaderContainerStyles,
} from "./uploader.interfaces";
import { flex, spacing } from "../../../styles/Theme/styled-options";
import rem from "../../../utils/rem";

export const UploaderContainer = styled.div<IUploaderContainerStyles>`
  .uploader__inner {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.grey[100] : theme.colors.grey[50]};
    border: 1px dashed ${({ theme }) => theme.colors.grey[100]};
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: ${({ theme }) => theme.spaceRadius.xxms};
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
    transition: background-color 0.2s ease;

    ${({ $disabled, theme }) =>
      $disabled
        ? undefined
        : css`
            &:hover {
              background: ${`linear-gradient(90deg, ${theme.colors.primary.base} 0%, ${theme.colors.primary[300]} 100%)`};
              border-color: transparent;

              .uploader {
                &__text,
                &__description {
                  color: #ffffff;

                  > strong {
                    color: #ffffff;
                  }
                }
              }
            }
          `}
  }

  .uploader__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .uploader__icon {
    width: 40px;
    height: 40px;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.grey[50] : theme.colors.primary[50]};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    > svg {
      color: ${({ theme, $disabled }) =>
        $disabled ? theme.colors.grey[600] : theme.colors.primary.base};
      font-size: ${rem(26)};
    }
  }

  .uploader__text,
  .uploader__description {
    font-size: ${rem(14)};
    font-weight: 400;
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.grey[600] : theme.colors.text};

    > strong {
      color: ${({ theme, $disabled }) =>
        $disabled ? theme.colors.grey[600] : theme.colors.primary.base};
      font-weight: 600;
    }
  }

  .uploader__text {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .uploader__preview {
    max-width: 100%;
    max-height: 105px;
  }
`;

const UploaderWrapper = styled.div<IUploaderWrapperStyles>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spaceRadius.form};
  width: ${({ $width }) => $width};
  max-width: 100%;
  ${spacing};
  ${flex};
`;

export default UploaderWrapper;
