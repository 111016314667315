import { makeVar } from "shared/providers/apollo/apollo.package";
import { TopbarTitle } from "shared/components/layout/topbar/topbar.interfaces";

/**
 * Control topbar title and meta
 */

export const titleVar = makeVar<TopbarTitle>({
  type: "default",
  value: "Districts",
  metaTitle: "District Admin - PRACTICE Software",
  metaDescription: "All districts with their schools, users and settings.",
});
