import { useCallback } from "react";
import { toast } from "shared/providers/toast/toast.package";

import SharedUploader from "shared/components/form/uploader/uploader.component";

import { useUploadFilesMutation } from "../../generated/graphql";
import { LocalUploaderProps } from "./uploader.interfaces";

const Uploader = (props: LocalUploaderProps) => {
  const [uploadFiles, { loading }] = useUploadFilesMutation();

  const onChange = useCallback(
    (accepted: File[], id: string) => {
      if (props.multiple) {
        if (props.customOnChange) {
          props.customOnChange(accepted, id);
        } else if (props.onChange) {
          uploadFiles({
            variables: {
              files: accepted,
            },
            onCompleted: (data) => {
              props.onChange!(
                data.uploadFiles.map(({ url, name, size }) => ({
                  url,
                  name,
                  size,
                })),
                id
              );
            },
            onError: (err) => {
              console.log(err);
              toast.error(err.message);
            },
          });
        }
      } else {
        if (props.customOnChange) {
          props.customOnChange(accepted[0], id);
        } else if (props.onChange) {
          uploadFiles({
            variables: {
              files: [accepted[0]],
            },
            onCompleted: (data) => {
              props.onChange!(
                {
                  url: data.uploadFiles[0].url,
                  name: data.uploadFiles[0].name,
                  size: data.uploadFiles[0].size,
                },
                id
              );
            },
            onError: (err) => {
              console.log(err);
              toast.error(err.message);
            },
          });
        }
      }
    },
    [uploadFiles, props]
  );

  return <SharedUploader {...props} onChange={onChange} loading={loading} />;
};

export default Uploader;
