import { makeVar } from "shared/providers/apollo/apollo.package";

export interface IBackgroundBarVar {
  height?: string | null;
  backgroundColor?: string | null;
}

/**
 * Control height of background
 */

export const backgroundBarVar = makeVar<IBackgroundBarVar | null>(null);
